import React, { useEffect, useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import { RootContext } from "../../contexts/root-context";
import axios from "axios";
import { BASE_URL } from "../../strings";
import { getToken } from "./get-token";

const UserSession = () => {

    const navigate = useNavigate();
    const location = useLocation()
    const { authDetails, setauthDetails } = useContext(RootContext);


    const reloadPage = async () => {
        let username = localStorage.getItem(
            `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}.LastAuthUser`
        );

        // if (username.startsWith("BedasSAML_")) {
        //     username = username.substring("BedasSAML_".length);
        try {
            const response = await axios.get(
                BASE_URL + `rbac-get?id=` + username.trim(), getToken()
            );
            let userInfo = response.data;

            //   setauthDetails([{ ...authDetails, userInfo: userInfo }]);
            localStorage.setItem("USER_INFO", JSON.stringify(userInfo));
            localStorage.removeItem("firstLoggedIn");
        } catch (error) {

        }
        // }
        window.location.reload();
    }
    useEffect(() => {

        checkUserSession()
    }, []);

    const checkUserSession = async () => {
        try {
            const currentSession = await Auth.currentSession()
                .then(session => {
                    // 

                    if (location.pathname === "/") {
                        reloadPage();
                        navigate("./substation-explorer");

                    }
                    //  Check if the access token is expired
                    const accessToken = session.getAccessToken();

                    const expirationTime = new Date(accessToken.payload.exp * 1000);

                    const currentTime = new Date();

                    if (expirationTime < currentTime) {


                        // Refresh the session using the refresh token
                        const refreshedSession = Auth.refreshSession(
                            currentSession.refreshToken
                        );


                    }
                })


        } catch (error) {

            // 
            if (error == "No current user") {


                navigate("/");
                //   reloadPage();
            }
        } finally {

        }
    };
    checkUserSession();


    return (<></>)
};

export default UserSession;
