import React, { useContext, useEffect } from "react";
import { Icon } from "@abb/abb-common-ux-react";
import "./dashboard.css";
import { RootContext } from "../../contexts/root-context";
function DashboardMetrics(props) {
    const { startTS, selectedTimeTitle } = useContext(RootContext);
    useEffect(() => {
    }, [startTS])
    //Metrics Names
    const { legends } = props;
    return (
        <div style={{ backgroundColor: 'white' }}>
            {legends.map((legend, index) => {
                switch (legend.metricName) {
                    case "# Trip Events":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>

                                <span className="metric-stat-dash metric-desc">
                                    <span style={{ float: "left" }}>
                                        <Icon name={legend.metricStat < 0 ? "abb/down-arrow" :
                                            legend.metricStat > 0 ? "abb/up-arrow" : "abb/minus"}
                                            sizeClass="medium" className={legend.metricStat < 0 ?
                                                "metric-green-arrow" :
                                                legend.metricStat > 0 ? "metric-red-arrow" : "metric-nochange"} ></Icon>
                                    </span>

                                    <span style={{ lineHeight: "24px" }}
                                        className={legend.metricStat < 0 ?
                                            "metric-green" :
                                            legend.metricStat > 0 ? "metric-red" : "metric-nochange"}>
                                        {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)} from {selectedTimeTitle}
                                    </span>
                                </span>

                            </div>
                        </div>
                    case "# Disturbance records":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>
                                    {legend?.metricScore}</span>

                                <span className="metric-stat-dash metric-desc">
                                    <span style={{ float: "left" }}>
                                        <Icon name={legend.metricStat < 0 ? "abb/down-arrow" :
                                            legend.metricStat > 0 ? "abb/up-arrow" : "abb/minus"}
                                            sizeClass="medium" className={legend.metricStat < 0 ?
                                                "metric-green-arrow" :
                                                legend.metricStat > 0 ? "metric-red-arrow" : "metric-nochange"} ></Icon>
                                    </span>

                                    <span style={{ lineHeight: "24px" }}
                                        className={legend.metricStat < 0 ?
                                            "metric-green" :
                                            legend.metricStat > 0 ? "metric-red" : "metric-nochange"}>
                                        {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)} from {selectedTimeTitle}
                                    </span>
                                </span>

                            </div>
                        </div>
                    case "# Unacknowledged DR":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>

                                <span className="metric-stat-dash metric-desc">
                                    <span style={{ float: "left" }}>
                                        <Icon name={legend.metricStat < 0 ? "abb/down-arrow" :
                                            legend.metricStat > 0 ? "abb/up-arrow" : "abb/minus"}
                                            sizeClass="medium" className={legend.metricStat < 0 ?
                                                "metric-green-arrow" :
                                                legend.metricStat > 0 ? "metric-red-arrow" : "metric-nochange"} ></Icon>
                                    </span>

                                    <span style={{ lineHeight: "24px" }}
                                        className={legend.metricStat < 0 ?
                                            "metric-green" :
                                            legend.metricStat > 0 ? "metric-red" : "metric-nochange"}>
                                        {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)} from {selectedTimeTitle}
                                    </span>
                                </span>

                            </div>
                        </div>
                    case "# Acknowledged DR":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat > 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat < 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>

                                <span className="metric-stat-dash metric-desc">
                                    <span style={{ float: "left" }}>
                                        <Icon name={legend.metricStat > 0 ? "abb/up-arrow" :
                                            legend.metricStat < 0 ? "abb/down-arrow" : "abb/minus"}
                                            sizeClass="medium" className={legend.metricStat > 0 ?
                                                "metric-green-arrow" :
                                                legend.metricStat < 0 ? "metric-red-arrow" : "metric-nochange"} ></Icon>
                                    </span>

                                    <span style={{ lineHeight: "24px" }} className={legend.metricStat > 0 ?
                                        "metric-green" :
                                        legend.metricStat < 0 ? "metric-red" : "metric-nochange"}>
                                        {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)} from {selectedTimeTitle}
                                    </span>
                                </span>

                            </div>
                        </div>
                    case "# Healthy Relays":
                        return <div id="dashboard-metrics" key={index}>

                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat > 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat < 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>

                                <span className="metric-stat-dash metric-desc">
                                    <span style={{ float: "left" }}>
                                        <Icon name={legend.metricStat > 0 ? "abb/up-arrow" :
                                            legend.metricStat < 0 ? "abb/down-arrow" : "abb/minus"}
                                            sizeClass="medium" className={legend.metricStat > 0 ?
                                                "metric-green-arrow" :
                                                legend.metricStat < 0 ? "metric-red-arrow" : "metric-nochange"} ></Icon>
                                    </span>

                                    <span style={{ lineHeight: "24px" }} className={legend.metricStat > 0 ?
                                        "metric-green" :
                                        legend.metricStat < 0 ? "metric-red" : "metric-nochange"}>
                                        {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)} from {selectedTimeTitle}
                                    </span>
                                </span>

                            </div>
                        </div>
                    case "# Unhealthy Relays":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>

                                <span className="metric-stat-dash metric-desc">
                                    <span style={{ float: "left" }}>
                                        <Icon name={legend.metricStat < 0 ? "abb/down-arrow" :
                                            legend.metricStat > 0 ? "abb/up-arrow" : "abb/minus"}
                                            sizeClass="medium" className={legend.metricStat < 0 ?
                                                "metric-green-arrow" :
                                                legend.metricStat > 0 ? "metric-red-arrow" : "metric-nochange"} ></Icon>
                                    </span>

                                    <span style={{ lineHeight: "24px" }}
                                        className={legend.metricStat < 0 ?
                                            "metric-green" :
                                            legend.metricStat > 0 ? "metric-red" : "metric-nochange"}>
                                        {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)} from {selectedTimeTitle}
                                    </span>
                                </span>

                            </div>
                        </div>
                }
            })
            }
        </div >
    );

}
export default DashboardMetrics;


