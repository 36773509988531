import React, { useContext, useEffect } from 'react';
import {
    NotificationContainer
} from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import "./notification.css";
export default function NotificationExample(props) {
    const { drwidgetConfig } = useContext(RootContext);
    const { notitype } = props;
    const [notiData] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            timeout: 3000,
            text: `Please wait while we open the file in wavewin`,
        }]
    }];
    const [errornotiData] = [{
        errornotifications: [{
            id: 2,
            type: 'banner',
            discreet: 2,
            severity: "warn",
            timeout: 3000,
            text: `Error while generating url.`,
        }]
    }];
    const [updatepwd] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 5000,
            text: `Your password has been changed successfully`,
        }]
    }];
    const [dracked] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `DR File ${drwidgetConfig[0].currentFile} Successfully Acknowledged`,
        }]
    }];
    const [generateReport] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            timeout: 3000,
            text: `Please wait while we generate the report. 
            You will be notified via email, When the report is ready.`,

        }]
    }];
    const [nodr] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: `No DR files available for the given inputs`,
        }]
    }];
    const [hist] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `Report sent to you mail`,
        }]
    }];
    const [userRemove] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            timeout: 3000,
            text: `User deleted successfully.`,
        }]
    }];
    const [sldsuccess] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `File successfully uploaded.`,
        }]
    }];

    const [sldfaliure] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: `Failed to upload.`,
        }]
    }];

    const [sldlarge] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: `File size exceeds 5MB. Please choose a smaller file.`,
        }]
    }];

    const [sldInvalid] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: `Invalid file format. Please choose a PDF file.`,
        }]
    }];

    const [subsuccess] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `Substations details updated successfully.`,
        }]
    }];
    const [subfaliure] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: `Failed to upload file content.`,
        }]
    }];
    const [subDelete] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `Substation deleted successfully.`,
        }]
    }];
    const [noSubAssign] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            text: `No operation area assigned, Please contact Ops. Manager.`,
        }]
    }];
    const [loadingSub] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            text: `Please wait while we fetch the substations from the assigned operation areas`,
        }]
    }];
    const [postLogout] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            timeout: 5000,
            text: `User has been logged out, please wait while redirecting to login.`,
        }]
    }];
    return (
        <>
            {notitype === "true" && <NotificationContainer notifications={notiData.notifications} style={{ position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "error" && <NotificationContainer notifications={errornotiData.errornotifications} style={{ position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "updatePassword" && <NotificationContainer notifications={updatepwd.notifications} style={{ position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "dr-acked" && <NotificationContainer notifications={dracked.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "genrateReport" && <NotificationContainer notifications={generateReport.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "nodr" && <NotificationContainer notifications={nodr.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "hr" && <NotificationContainer notifications={hist.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "sub-success" && <NotificationContainer notifications={subsuccess.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "sub-failure" && <NotificationContainer notifications={subfaliure.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "sub-delete" && <NotificationContainer notifications={subDelete.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "remove-user" && <NotificationContainer notifications={userRemove.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "sld-success" && <NotificationContainer notifications={sldsuccess.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "sld-failure" && <NotificationContainer notifications={sldfaliure.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "sld-too-large" && <NotificationContainer notifications={sldlarge.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "sld-invalid-format" && <NotificationContainer notifications={sldInvalid.notifications} style={{ textAlign: "left", position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />
            }
            {notitype === "no-sub-assign" && <NotificationContainer notifications={noSubAssign.notifications} style={{ textAlign: "left", position: 'absolute', top: '50px', right: '-5px', zIndex: 9 }} />}
            {notitype === "loading-substations" && <NotificationContainer notifications={loadingSub.notifications} style={{ textAlign: "left", position: 'absolute', top: '125px', right: '-5px', zIndex: 9 }} />}
            {notitype === "go-back-login" && <NotificationContainer notifications={postLogout.notifications} style={{ textAlign: "left", position: 'absolute', top: '50px', right: '-5px', zIndex: 9 }} />}
        </>
    );

}
<NotificationExample />;