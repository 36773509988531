import "./App.css";
import React, { useEffect, useContext, useState, useMemo } from "react";
import "@abb/abb-common-ux-react/styles.css";
import { RootProvider } from "./contexts/root-context";
import Dashboard from "./pages/dashboard/dashboard";
import Navbar from "./components/Navbar/navbar";
import { getDefaultTimeStamp } from "./util";
import ABBLogin from "./pages/login/login";
import CogniENRoutes from "./routes";
import { Amplify } from 'aws-amplify';
import { awsConfig } from "./components/Authentication/aws-config";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter } from "react-router-dom/dist";

function App() {
  Amplify.configure(awsConfig);
  const [SpinnerVal, setSpinner] = React.useState(false);
  const [isLeftPaneOpen, setisLeftPaneOpen] = React.useState("full");
  let defaultTS = getDefaultTimeStamp();
  let input = localStorage.getItem("filterInput") ? JSON.parse(localStorage.getItem("filterInput")) :
    defaultTS;
  const [startTS, setstartTS] = React.useState(input?.start ? input.start : defaultTS.startDate);
  const [endTS, setendTS] = React.useState(input?.end ? input.end : defaultTS.endDate);
  const [selectedTimeTitle, setselectedTimeTitle] = React.useState(input?.title ? input.title : "Last 24 hours")
  const [value, setValue] = React.useState(42);
  const [range, setRange] = React.useState([0, 100]);
  const [decimals, setDecimals] = React.useState(0);
  const [warnings, setWarnings] = React.useState([25, 75]);
  const [alarms, setAlarms] = React.useState([10, 90]);
  const [limitMarkerStyle, setLimitMarkerStyle] = React.useState("parallel");
  const [isnotify, setisnotify] = React.useState("");
  const [mapMarkerErrorMsg, setmapMarkerErrorMsg] = React.useState("");
  const [markerloading, setMarkerLoading] = React.useState("");
  const [username, setusername] = React.useState("");
  const [primaryFilter, setprimaryFilter] = React.useState([]);
  const [opsAreaFilter, setOpsAreaFilter] = React.useState([]);

  const [checkedValues, setCheckedValues] = React.useState([]);
  //DR Metrics
  const [relay, setrelayMetrics] = React.useState([]);
  const [drmetrics, setdrmetrics] = React.useState([]);
  const [subIds, setSubIds] = useState([]);
  //No.of records in notification widget
  const [totalDistRowCounts, settotalDistRowCounts] = useState(0);
  const [totalNotiRecords, settotalNotiRecords] = React.useState(0);
  //Notification handling
  const [drwidgetConfig, setdrwidgetConfig] = React.useState([{ isDialogOpen: false, isDRSucceedNofify: false, currentFile: "" }]);
  const [authDetails, setauthDetails] = React.useState();
  //  const [authDetails, setauthDetails] = React.useState(JSON.parse(localStorage.getItem("currentUserInfo")) || []);


  return (
    <div className="App">

      <div className="container-fluid">

        <RootProvider
          value={{
            setSpinner,
            SpinnerVal,
            mapMarkerErrorMsg,
            setmapMarkerErrorMsg,
            markerloading,
            setMarkerLoading,
            startTS,
            setstartTS,
            endTS,
            setendTS,
            selectedTimeTitle,
            setselectedTimeTitle,
            isnotify,
            setisnotify,
            authDetails,
            setauthDetails,
            isLeftPaneOpen,
            setisLeftPaneOpen,
            primaryFilter,
            setprimaryFilter,
            opsAreaFilter,
            setOpsAreaFilter,
            checkedValues,
            setCheckedValues,
            drwidgetConfig,
            setdrwidgetConfig,
            totalDistRowCounts,
            settotalDistRowCounts,
            totalNotiRecords,
            settotalNotiRecords,
            relay,
            setrelayMetrics,
            drmetrics,
            setdrmetrics,
            subIds,
            setSubIds,
          }}
        >
          <HashRouter >
            <CogniENRoutes />
          </HashRouter>
        </RootProvider>
      </div>


    </div >
  );
}

export default App;
