import React, { useEffect, useState, useContext } from "react";
import { LoginScreen, Link, Icon } from "@abb/abb-common-ux-react";
import { useNavigate } from "react-router-dom";
import { RootContext } from "../../contexts/root-context";
import { Auth, Amplify } from "aws-amplify";
import NotificationExample from "../../components/Notification/notification";
import { awsConfig } from "../../components/Authentication/aws-config";
import { BASE_URL, BASE_URL_MFA, MESSAGES } from "../../strings";
import axios from "axios";
import { getToken } from "../../components/Authentication/get-token";
import "./login.css";
import { encryptData } from "../../util";
import VerifyMFA from "../MFA/verify-mfa";
import { BASE_URL_LOG } from "../../strings";


Amplify.configure(awsConfig);

function ABBLogin() {
  const { isnotify } = useContext(RootContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [showVerifyMFA, setShowVerifyMFA] = useState(false);

  useEffect(() => {
    localStorage.setItem("error", "");
    redirectToLogin();
  }, [isError]);

  useEffect(() => {

  }, [showVerifyMFA])

  const handleForgotPasswordClick = () => {
    navigate("../forgot-password");
  };

  const redirectToLogin = () => {
    try {
      if (window.location.pathname !== "/") {
        navigate("../");
      }
    } catch (err) { }
  };

  const showSuccess = () => {
    navigate("./substation-explorer");
    window.location.reload();
  };

  const lawText =
    "This is a private system. Do not attempt to logon unless you are an authorized user. " +
    "Any authorized or unauthorized access and use may be monitored and can result in " +
    "criminal or civil prosecution under applicable law.";

  const [usersstate, setUsersState] = useState({
    username: "",
    password: "",
    error: false,
  });

  const gotSignUp = () => {
    navigate("../signup");
  };

  const goForgotPassword = () => {
    navigate("../change-password");
  };

  const postLoginFetchUserDetails = async (username) => {
    try {
      const response = await axios.get(
        BASE_URL + `rbac-get?id=` + username.trim(),
        getToken()
      );
      const userInfo = response.data;
      localStorage.setItem("USER_INFO", JSON.stringify(userInfo));
      localStorage.removeItem("firstLoggedIn");
    } catch (error) { }
  };

  const handleLoginSubmit = async (username, password) => {
    localStorage.clear();
    setIsError(false);
    setLoading(true);

    try {
      const signInOptions = {
        username,
        password,
      };

      const user = await Auth.signIn(signInOptions);


      // Check if MFA is required for the user
      const challengeParam = user.challengeName;

      if (challengeParam === "MFA_SETUP") {
        try {
          const mfaSetupURL = BASE_URL_MFA;
          window.location.href = mfaSetupURL;
        } catch (error) {
          console.error("Error during MFA setup:", error);
        }
      } else if (challengeParam === "SOFTWARE_TOKEN_MFA") {
        // setShowVerifyMFA(true);


        const totpCode = prompt(
          "Enter One--time password code from your authenticator app:"
        );

        var confirmSignInResp = await Auth.confirmSignIn(
          user,
          totpCode,
          "SOFTWARE_TOKEN_MFA"
        );



        // Check if MFA challenge is successfully completed before proceeding
        if (confirmSignInResp.username) {
          await postLoginFetchUserDetails(username);
          setLoading(false);
          showSuccess();
        }
      }

      const encryptedUserName = encryptData(username);
      localStorage.setItem("logged_in_user", encryptedUserName);
      localStorage.setItem("error", "");
      setIsError(false);
      fetch(BASE_URL_LOG + `login-success?userId=${username}`)
      setUsersState({ ...usersstate, iserror: false });
    } catch (error) {
      setLoading(false);
      setIsError(true);
      fetch(BASE_URL_LOG + `login-failure?userId=${username}`);
      localStorage.setItem("error", error.toString().split(":")[1]);
      if (error.toString().includes("attempts exceeded")) {
        localStorage.setItem("error", "Incorrect username or password.");
      }
      setUsersState({
        ...usersstate,
        iserror: true,
        error: error.toString().split(":")[1],
      });
    }
  };

  const handleIdpLogin = async () => {
    try {
      Auth.federatedSignIn({ provider: "SAML" });
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      {showVerifyMFA ? (
        <VerifyMFA usernames={"user"} />
      ) :
        (<LoginScreen
          productName="Log in"
          className="login-container"
          footerText={lawText}
          onLogin={(username, password) => {
            setCount(count + 1);
            if (username === "" || password === "") {
              setIsError(true);
              localStorage.setItem("error", "Enter Valid Username and password");
            } else if (username && password) {
              handleLoginSubmit(username, password);
            } else {
              setIsError(true);
              localStorage.setItem("error", "Enter Valid Username and password");
            }
          }}
          customContent={(props, state) => {
            setUsersState(state);
            return (
              <div className="success">
                {loading && (
                  <p style={{ color: "green" }}>{MESSAGES.SIGN_IN_TRIGGER}</p>
                )}
                <div className="success2">
                  {localStorage.getItem("error") && (
                    <p style={{ color: "red" }} id="error-msg">
                      <Icon
                        name="abb/warning-circle-1"
                        className="incorrect"
                      ></Icon>
                      {localStorage.getItem("error")}
                    </p>
                  )}
                </div>
                <div>
                  <br />
                  Don't have an account <Link onClick={gotSignUp}>SignUp</Link>
                  <br />
                </div>
              </div>
            );
          }}
        />)}
    </>
  );
}

export default ABBLogin;
