import React, { useState, useContext, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import "./verify-mfa.css";
const VerifyMFA = (props) => {
    const { usernames } = props;

    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [resetPasswordStep, setResetPasswordStep] = useState(1);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [isValidnewpwd, setisValidnewpwd] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [code, setCode] = useState('');
    const [formValues, setFormValues] = useState({
        email: "",
        code: "",
        newpassword: "",
        confirmpassword: "",
    });
    function validateEmail(email) {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the email against the regex
        return emailRegex.test(email);
    }

    useEffect(() => {

    }, [formErrors, isValidnewpwd])

    const handleEmailChange = async (e) => {
        setFormErrors({})
        setUsername(e.target.value)
        setFormValues(prevState => ({ ...prevState, [e?.target?.id]: e.target.value }));
    }

    const handlePasswordChange = (e) => {
        let newPwd = e.target.value;
        setFormValues(prevState => ({ ...prevState, [e?.target?.id]: newPwd }));

        checkPwdRules(e.target.value)
        if (!isValid) {
            setFormErrors(prevState => ({ ...prevState, passowrd: "Password must contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter." }))
        } else {
            setFormErrors({})
        }
    };
    const handleConfirmPwd = (e) => {
        const newValue = e.target.value;
        setInputValue((prevValue) => {
            return newValue;
        });
    };
    const handleConfirmCode = (e) => {
        const newValue = e.target.value;
        setCode((prevValue) => {
            return newValue;
        });
    };

    const checkPwdRules = (newPassword) => {
        // Password validation regular expressions
        const hasNumber = /\d/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasUpperCase = /[A-Z]/.test(newPassword);
        const hasLowerCase = /[a-z]/.test(newPassword);
        // Check if all requirements are met
        setIsValid(hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase);
    }
    const sendVerificationCode = async () => {
        let getVerificationCodeErrors = {}
        if (!validateEmail(formValues.email)) {
            // errors.email = 'Enter valid email.';
            setFormErrors(prevState => ({ ...prevState, email: 'Enter valid email.' }))
            getVerificationCodeErrors.email = "Enter valid email."
        }
        if (Object.keys(getVerificationCodeErrors).length === 0) {

            try {
                await Auth.forgotPassword(username);
                setResetPasswordStep(2);
                setFormValues(prevState => ({
                    ...prevState,
                    code: "",
                    newpassword: "",
                }));
                setFormErrors({})
            } catch (error) {
                if (error.toString().includes("AuthError") == false) {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
                }
            }
        }
    };

    const cancelForgotPwd = () => {
        navigate("../");
    }

    const resetPassword = async () => {

        checkPwdRules(formValues.passowrd)

        if (formValues.password != inputValue) {
            setFormErrors(prevState => ({ ...prevState, confirmpassword: "Confirm password must be match with the password" }))
            setisValidnewpwd(true)
        } else {
            setisValidnewpwd(false)

            setFormErrors({})
        }
        if (Object.keys(formErrors).length === 0) {
            try {
                await Auth.forgotPasswordSubmit(formValues.email, code, inputValue);
                setResetPasswordStep(3);
                navigate("../");
            } catch (error) {
                setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
            }
        }
    };
    const resendVerificationCode = async () => {
        try {
            await Auth.resendSignUp(username);

        } catch (error) {
            console.error('Error resending verification code', error);
        }
    };

    return (
        <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">

            <div className="ABB_CommonUX_LoginScreen__header">
                <div />
                <div className="ABB_CommonUX_LoginScreen__logo" />
            </div>
            <div className="ABB_CommonUX_LoginScreen__contentWrapper">

                <div className="ABB_CommonUX_LoginScreen__content">

                    <h1 className="ABB_CommonUX_LoginScreen__productName">Verify your identity</h1>

                    <div className="ABB_CommonUX_LoginScreen__form">

                        {resetPasswordStep === 1 &&
                            <>
                                <div className="ABB_CommonUX_LoginScreen__formRow">
                                    <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                                        Enter a code from your authenticator app.
                                        <div />
                                    </div>
                                </div>
                                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ width: "400px", fontSize: "14px", fontWeight: 500, marginTop: "0px" }}>
                                    <div className="ABB_CommonUX_LoginScreen__password">
                                        <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">

                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                                                <input
                                                    className="ABB_CommonUX_Input__inputElement"
                                                    type="email"
                                                    required
                                                    name="email"
                                                    id="email"
                                                    onChange={handleEmailChange}

                                                />

                                            </div>
                                            {formErrors.email && <span className="error-display">{formErrors.email}</span>}


                                            <br />
                                            <br />
                                            <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="send-email" onClick={sendVerificationCode} >
                                                {/*  */}
                                                <div>
                                                    <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                        Login
                                                    </span>
                                                </div>
                                            </button>&nbsp;&nbsp;
                                            <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="forgot-email-cancel" onClick={cancelForgotPwd} >
                                                {/*  */}
                                                <div>
                                                    <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                        Cancel
                                                    </span>
                                                </div>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </>}
                        {formErrors.unauthmessage && <span className="error-display-pwd">{formErrors.unauthmessage}</span>}


                    </div>



                    {/* </form> */}
                </div>

            </div>


        </div>

    );
};

export default VerifyMFA;
