
import React, { useEffect, useState, useContext } from "react";
import { MessageDialog, NotificationContainer, NotificationData } from "@abb/abb-common-ux-react";
import "./signup.css";
import { useNavigate } from "react-router-dom";
function SignUpPostVerification() {
    const navigate = useNavigate();
    const showLogin = () => {
        navigate('/');
    }
    return (
        <div style={{
            width: '50%', margin: '0 auto', "max-width": '380px'
        }}>

            <MessageDialog
                buttons="Ok"
                message="Your email has been successfully verified. Please login with your username/email and password"
                title="Email verfication completed"
                onClose={() => { }}
                onOk={showLogin}
            />
        </div >)
}
export default SignUpPostVerification;