import React, { useContext, useEffect, useState } from "react";
import {
  DropdownOption,
  Dropdown,
  Button,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import getTimestamps from "../../util";
import { RootContext } from "../../contexts/root-context";
import "./substation-explorer.css";
import { BASE_URL } from "../../strings";
import NotificationExample from "../../components/Notification/notification";
import { getToken } from "../../components/Authentication/get-token";
import "./substation-filters.css"

function SubstationFilters(substationProps) {
  const { substationTitle } = substationProps;
  const [selectedSingle, setSelectedSingle] = React.useState(localStorage.getItem('selectedTimeFilter'));
  const {
    setdrmetrics,
    setrelayMetrics,
    setselectedTimeTitle,
    startTS,
    setstartTS,
    isnotify,
    setisnotify,
    endTS,
    setendTS,
    subIds,
    mapMarkerErrorMsg
  } = useContext(RootContext);


  const [error, setError] = useState(false);

  const checkAssignedSubstation = () => {
    const getCurrentSubstaations = JSON.parse(localStorage.getItem('USER_INFO'));

    if (getCurrentSubstaations?.operationAreas?.length > 0 && getCurrentSubstaations?.operationAreas[0] === "") {
      setisnotify("no-sub-assign");
    }

  }

  useEffect(() => {
    checkAssignedSubstation()
  }, [])

  useEffect(() => {
    checkAssignedSubstation();
    fetchRelayMetrics();
    fetchDRMetrics();
  }, [startTS, subIds]);

  const fetchRelayMetrics = async () => {
    try {
      const requestBody = {
        substationList: JSON.parse(localStorage.getItem('subIds'))
      };
      const response = await axios.post(
        BASE_URL +
        `substation-metrics?startDate=${startTS}&endDate=${endTS}`,
        requestBody, getToken()
      );
      let fetchedMetrics = response.data;
      setrelayMetrics(fetchedMetrics);
      setError(false);

    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
        } else if (status >= 500 && status < 600) {
          setError(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };
  const fetchDRMetrics = async () => {
    try {
      const requestBody = {
        substationList: JSON.parse(localStorage.getItem('subIds'))
      };

      const response = await axios.post(
        BASE_URL +
        `substation-dr-metrics?startDate=${startTS}&endDate=${endTS}`,
        requestBody, getToken()
      );
      let fetchedMetrics = response.data;
      setdrmetrics(fetchedMetrics);
      setError(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
        } else if (status >= 500 && status < 600) {
          setError(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };
  const filterOptions = [
    {
      title: "Last 5 minutes",
      value: 5,
    },
    {
      title: "Last 15 minutes",
      value: 15,
    },
    {
      title: "Last 30 minutes",
      value: 30,
    },
    {
      title: "Last 1 hour",
      value: 60,
    },
    {
      title: "Last 3 hours",
      value: 180,
    },
    {
      title: "Last 6 hours",
      value: 360,
    },
    {
      title: "Last 12 hours",
      value: 720,
    },
    {
      title: "Last 24 hours",
      value: 1440,
    },
    {
      title: "Last 3 days",
      value: 4320,
    },
  ];

  const getFilterTime = (filterVal) => {
    setSelectedSingle(String(filterVal[0].label));
    setstartTS(getTimestamps(filterVal[0].value)?.startDate);
    setendTS(getTimestamps(filterVal[0].value)?.endDate);
    setselectedTimeTitle(String(filterVal[0].label));
    localStorage.setItem('selectedTimeFilter', String(filterVal[0].label))
    localStorage.setItem("filterInput",
      JSON.stringify({
        title: String(filterVal[0].label),
        start: getTimestamps(filterVal[0].value)?.startDate,
        end: getTimestamps(filterVal[0].value)?.endDate
      }));
  };

  const setOnRefresh = () => {
    const dropDownValue = JSON.parse(localStorage.getItem("filterInput")).title;
    const timePeriod = filterOptions.filter(sub => sub.title == dropDownValue);
    const modifiedRefreshTimes = getTimestamps(timePeriod[0].value);
    setstartTS(modifiedRefreshTimes?.startDate);
    setendTS(modifiedRefreshTimes?.endDate);
    localStorage.setItem("filterInput",
      JSON.stringify({
        title: dropDownValue,
        start: startTS,
        end: endTS
      }));
  }


  return (
    <>

      <div className="col-5 col-m-12 col-s-12" style={{ padding: 0 }}>
        {isnotify === "no-sub-assign" ? (
          <NotificationExample notitype={isnotify} />
        ) : (
          ""
        )}
        <div className="filter-header">
          {substationTitle}
          &nbsp;
          <span className="last-updated">Last refresh on : {new Date().toLocaleString()}</span>

        </div>

      </div>

      <div className="col-7 col-m-12 col-s-12" style={{ padding: 0 }}>
        <span className="substation-waiting"> {mapMarkerErrorMsg}</span>

        <div className="filters-group">
          <Dropdown
            className="filter-dropdown col-s-12"
            label="Time Period"
            placeholder={selectedSingle ? selectedSingle : "Last 24 Hours"}
            monochrome={false}
            value={selectedSingle}
            onChange={getFilterTime}
          >
            {filterOptions.map((o, i) => (
              <DropdownOption key={o.title} label={o.title} value={o.value} />
            ))}
          </Dropdown>
          <Button
            style={{ margin: "10px" }}
            sizeClass={"small"}
            icon="abb/synchronize"
            type="normal"
            shape={"rounded"}
            text="Refresh"
            onClick={setOnRefresh}
          />
        </div>
      </div>
    </>
  );
}
export default SubstationFilters;
