import React, { useContext, useEffect } from 'react';
import { NotificationContainer } from "@abb/abb-common-ux-react";
import Navbar from '../../components/Navbar/navbar';
import { RootContext } from "../../contexts/root-context";
import { useNavigate } from "react-router-dom";
import NotificationExample from '../../components/Notification/notification';
import { Auth } from 'aws-amplify';
import UserSession from '../../components/Authentication/user-session';
import Map from './map';
import SubstationFilters from './substation-filters';

const SubstationExplorer = () => {

    const { isnotify, setisnotify, setusername, authDetails, selectedTimeTitle } = useContext(RootContext);
    return (
        <div >
            {/* <UserSession /> */}
            <div className="filter-row row">
                <SubstationFilters substationTitle={"Substation Explorer"} />
            </div>
            <div className='row'>


                <Map />

            </div>


        </div>
    );
}
export default SubstationExplorer;

