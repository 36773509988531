import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../strings";
import { getToken } from "../Authentication/get-token";
import "./sld.css";
const FullSld = () => {
  const [pdfBase64, setPdfBase64] = useState(null);
  const fetchPdfData = () => {
    fetch(BASE_URL + `sld-preview?substationId=${SUBSTATION_ID}.pdf`, getToken())
      .then((response) => response.text())
      .then((data) => {
        setPdfBase64(data);
      })
      .catch((error) => {
        console.error("Error fetching PDF content ", error);
      });
  }
  useEffect(() => {
    fetchPdfData()
  }, []);
  const SUBSTATION_ID = localStorage.getItem("selectedSubstationId");
  return (
    <>
      {
        pdfBase64 ?
          <iframe
            type="application/pdf"
            loading="lazy"
            src={`data:application/pdf;base64,${pdfBase64}`}
            width="100%"
            height="800px"
          /> : <div>Loading SLD...</div>}
    </>
  );
};
export default FullSld;
