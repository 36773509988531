import React, { useContext } from 'react';
import { RootContext } from "../../contexts/root-context";
import MapContainer from '../../components/Google-Map/MapContainer';
import LeftpaneComponent from '../../components/Leftpane/left-pane';

const Map = () => {
    return (
        <>
            <div>

                <LeftpaneComponent />
                <MapContainer />
            </div>
        </>
    );
}
export default Map;

