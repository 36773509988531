
import React from "react";
import { Dialog, Button, Input } from "@abb/abb-common-ux-react";
import { useNavigate } from "react-router-dom";
import "./signup.css";
function SignupSuccess() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState("");
    const showLogin = () => {
        navigate('/');
    }
    return (
        <div style={{
            width: '50%', margin: '0 auto', "max-width": '380px'
        }}>

            <Dialog
                buttons="Ok"
                showCloseButton={true}
                closeOnEscape={true}
                closeOnLostFocus={true}
                title="Confirm your account"
                onClose={() => { }}
            >
                <p>We have sent code by email.Enter it below to confirm account.</p>
                <span style={{ "display": "flex" }}>Verification code
                    &nbsp;<Input
                        type={"normal"}
                        dataType={'text'}
                        onValueChange={(val) => setValue(val)}
                    />
                </span>
                <br />
                <span>Didn't receive a code?&nbsp;<Button key="Confirm account" text="Confirm account" type="primary-blue" onClick={showLogin} /></span>
            </Dialog>
        </div >)
}
export default SignupSuccess;